enum AssociationStatus {
    FirstRequest = 0,
    ApprovedFromAssociation = 1,
    RemovedFromAssociation = 2,
    UserWithoutAssociation = 3,
  }
  
  // Mappa di traduzioni
  const associationStatusTranslations: { [key in AssociationStatus]: string } = {

    [AssociationStatus.FirstRequest]: "Richiesta in attesa di approvazione",
    [AssociationStatus.ApprovedFromAssociation]: "Utente approvato dall'associazione",
    [AssociationStatus.RemovedFromAssociation]: "Utente rimosso dall'associazione",
    [AssociationStatus.UserWithoutAssociation]: "Utente senza associazione",
  };
  
  // Funzione che restituisce la stringa tradotta
  export function getAssociationStatusLabel(status: number | null | undefined): string {
    if (status === null || status === undefined) {
        return "Stato sconosciuto";
    }
    return associationStatusTranslations[status as AssociationStatus] || "Stato sconosciuto";
  }
  