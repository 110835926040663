import React, { useState } from 'react';
import { TextField, FormHelperText } from '@mui/material';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';

interface TextFieldCustomProps {
  label: string;
  value: string;
  onChange?: (value: string) => void;
  errorText: string;
  regex?: RegExp;
  disable?: boolean;
  upperCase?:boolean;
  required?:boolean
}

function removeFirstSpaceChar(str:string) {
    if (str.charAt(0) === ' ') {
      return str.slice(1);
    } else {
      return str;
    }
}

const TextFieldCustom: React.FC<TextFieldCustomProps> = ({
  label,
  regex,
  value,
  onChange,
  errorText,
  disable=true,
  upperCase=false,
  required=false,
}) => {
  const [error, setError] = useState(false);
  const {t} = useTranslationContext();
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = removeFirstSpaceChar(event.target.value)
      if (value && upperCase)
          value = value.toUpperCase()
      if(onChange)
        onChange(value);
      if(regex){
        setError(!regex.test(value)); 
      }
  };

  return (
    <>
        <TextField
            margin="normal"
            required={required}
            fullWidth
            id={label}
            label={!error ? t(label): t("Errore")}
            name={label}
            autoComplete={label}
            // autoFocus
            value={value}
            onChange={handleChange}
            error={error}
            disabled={disable}
            helperText={
                error ? t(errorText) : ""
            }
        />
    </>
  );
};

export default TextFieldCustom;
