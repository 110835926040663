import React, { useState } from 'react';
import { TextField, FormHelperText } from '@mui/material';
import { useTranslationContext } from '../../hooks/translations/translationsProvider';

interface TextFieldCustomProps {
  label: string;
  value?: string | undefined;
  error: boolean;
  name:string;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (value: string) => void;
  errorText: string;
  regex?: RegExp;
  disable?: boolean;
  upperCase?:boolean;
  required?:boolean;
  multiline?: boolean;
}

function removeFirstSpaceChar(str:string) {
    if (str.charAt(0) === ' ') {
      return str.slice(1);
    } else {
      return str;
    }
}

const TextFieldWithErrorPropsCustom: React.FC<TextFieldCustomProps> = ({
  label,
  regex,
  value,
  error,
  name,
  setError,
  onChange,
  errorText,
  disable=false,
  upperCase=false,
  required=true,
  multiline=false
}) => {
  const {t} = useTranslationContext();
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = removeFirstSpaceChar(event.target.value)
      if (value && upperCase)
          value = value.toUpperCase()
      if(onChange)
        onChange(value);
      if(regex){
        if(value && value.length > 0)
          setError(!regex.test(value)); 
        else
          setError(false);
      }
  };
  
  function rows (){
    if(multiline)
      return 4;
    else
      return undefined;
  }

  return (
    <>
        <TextField
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={!error ? label: t("Errore")}
            name={name}
            autoComplete={label}
            multiline={multiline}
            value={value}
            onChange={handleChange}
            error={error}
            disabled={disable}
            helperText={
                error ? errorText : ""
            }
            rows={rows()}
        />
    </>
  );
};

export default TextFieldWithErrorPropsCustom;
