import { Container, styled, Box } from '@mui/material';
import { WHITE, BLUE_LIGHT_RGB_BORDER } from '../theme/colors';
import { isMobile } from 'react-device-detect';

export const AppContainer = styled('div')(({theme})=>({
  display:'flex',
  textAlign:'center',
  flexDirection: 'column',
  // minHeight:'100vh'
}))
  
interface ContainerCustomProps{
  headerH:number
}
export const ContainerCustom = styled(Container)<ContainerCustomProps>(({theme, headerH})=>({
  position:'relative',
  display:'flex',
  flexGrow:1,
  paddingTop: headerH >= 50 ? headerH +'px' : '150px',
  paddingBottom: '100px'
}))
  
export const FooterContainer = styled('div')(({theme})=>({
  bottom:'0px',
  left:'0px',
  width:'100%',
  position:'fixed',
  display:'flex',
  height:!isMobile ? '50px' : '56px',
  backgroundColor:WHITE,
  borderTop:"1px solid " + BLUE_LIGHT_RGB_BORDER,
  zIndex:1000,
}))

export const WaiterContainer = styled('div')(({theme})=>({
  position:'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top:0,
  left:0,
  bottom:0,
  right:0,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  zIndex:1
}))